#aboutHolder {
  width: 40vw;
  margin: auto;
}

#aboutText {
  font-family: "Helvetica";
  color: white;
}

@media (max-width: 900px) {
  #aboutHolder {
    width: 90vw;
    margin: auto;
  }
}
