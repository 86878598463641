.NavButton {
  border-radius: 10px;
  width: 100px;
  height: 23px;
  background-color: black;
  border: 2px solid white;
  margin-right: 10px;
  cursor: pointer;
}

.NavButton:hover {
  background-color: blue;
}

.innerText {
  margin: 0;
  color: white;
  font-size: 1.2em;
}

@media (max-width: 900px) {
  .NavButton {
    border-radius: 10px;
    width: 100px;
    height: 23px;
    background-color: black;
    border: 2px solid white;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
