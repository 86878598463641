.musicResults {
  color: white;
  width: 60vw;
  margin: auto;
  margin-top: 30px;
  text-align: center;
}

.musicResults th,
td {
  border: 1px solid white;
}

@media (max-width: 960px) {
  .musicResults {
    color: white;
    width: 90vw;
    margin: auto;
    margin-top: 30px;
    text-align: center;
  }
}
