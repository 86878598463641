#CenterText {
  width: 40vw;
  margin: auto;
  padding-top: 20vh;
  text-align: center;
  color: white;
  font-family: "Raleway";
  font-weight: 800;
}

#CenterTextGame {
  width: 100%;
  padding-top: 5vh;
  text-align: center;
  color: white;
  font-family: "Raleway";
  font-weight: 800;
}

#CenterTextHidden {
  width: 40vw;
  margin: auto;
  padding-top: 20vh;
  text-align: center;
  font-weight: 800;
  font-size: 10vw;
}

#CenterTextHiddenGame {
  width: 100%;
  padding-top: 5vh;
  text-align: center;
  font-weight: 800;
  font-size: 10vw;
}

#CenterText h1 {
  font-size: 10vw;
}

#CenterTextGame h1 {
  font-size: 4vw;
}

#underText {
  font-family: Helvetica;
}

a {
  color: white;
}

@media (max-width: 900px) {
  #CenterText {
    width: 60vw;
    margin: auto;
    padding-top: 3vh;
    text-align: center;
    color: white;
    font-family: "Raleway";
    font-weight: 800;
  }

  #CenterText h1 {
    font-size: 15vw;
  }

  #CenterTextGame h1 {
    font-size: 9vw;
  }
}
