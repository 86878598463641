.MusicPlayer {
  width: 70vw;
  height: 60px;
  background-color: black;
  margin: auto;
  border: 1px solid white;
}

table {
  width: 100%;
}

th {
  border-right: 1px solid white;
  padding: 0;
}

.playPause {
  text-align: center;
  filter: invert(100%);
}

.progressBar {
  width: 70vw;
  height: 19px;
  display: block;
}

.svgHolder {
  width: 70vw;
  margin: auto;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-top: 1px solid white;
}

.playButton {
  border: 0;
  background: transparent;
  cursor: pointer;
}

.headerRef {
  display: auto;
}

@media (max-width: 975px) {
  .MusicPlayer {
    width: 90vw;
    height: 60px;
    background-color: black;
    margin: auto;
  }

  .progressBar {
    width: 90vw;
    height: 19px;
    display: block;
  }

  .svgHolder {
    width: 90vw;
    margin: auto;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-top: 1px solid white;
  }

  .playButton {
    border: 0;
    background: transparent;
    cursor: pointer;
  }

  .headerRef {
    display: none;
  }
}
