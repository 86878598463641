.App {
  width: 100vw;
  height: 100vh;
  background-color: black;
}

#buttonHolder {
  width: 40vw;
  margin: auto;
  margin-top: 10px;
  text-align: center;
}

#key {
  width: 50vw;
  margin: auto;
  text-align: center;
  padding-top: 30px;
  color: white;
  line-height: 2;
}

#content-wrap {
  padding-bottom: 2.5rem;
}

@media (max-width: 900px) {
  #key {
    width: 75vw;
    margin: auto;
    text-align: center;
    padding-top: 30px;
    color: white;
    line-height: 1.35;
  }

  #content-wrap {
    padding-bottom: 0.5rem;
  }
}
